import Link from "next/link";
import { ExecutorFunction, useResources } from "resourcerer";

import CambioPage from "@/components/base/cards/CambioPage";
import Button from "@/components/Button";
import IconButton from "@/components/Button/IconButton";
import { ErrorState } from "@/components/EmptyState";
import HovercardToggle from "@/components/HovercardToggle";
import { withModalContextProvider } from "@/components/Modal/ModalContext";
import { withNotificationContextProvider } from "@/components/Notification/NotificationContext";
import SelectableList from "@/components/SelectableList";
import Spinner from "@/components/Spinner";
import SvgIcon from "@/components/SvgIcon";
import Table from "@/components/Table";

import { GOLDMAN_ORGANIZATION_TOKEN } from "@/js/constants/cambio";
import useModalContext from "@/js/hooks/useModalContext";
import { logout } from "@/js/utils/authentication";
import { getEmptyCell, getLoadingCell } from "@/js/utils/partials";

import { EnhancedSpace } from "@/Api/generated";
import CambioLogo from "@/images/logo.svg";

import SpaceMeterAssignmentModal from "../DrilldownPage/MetersTab/SpaceMeterAssignmentModal";

export const getResources: ExecutorFunction<"properties", { organizationToken: string }> = (
  props,
) => ({
  properties: {
    params: { include_area: true, organization_token: props.organizationToken },
  },
});

/**
 * While contracts are worked out, Goldman can't actually log into the full Cambio app. But we still
 * want to ingest their data, so our solution is this temporary page that they see when they login,
 * which is just a table of their properties, each row of which launches the SMA modal that they can
 * set up.
 *
 * This is hacked together because we'll remove it as soon as they get access to the full Cambio
 * app.
 */
function TempGoldmanHomePage() {
  const { isLoading, hasLoaded, hasErrored, propertiesCollection } = useResources(getResources, {
    organizationToken: GOLDMAN_ORGANIZATION_TOKEN,
  });

  return (
    <CambioPage title="Home">
      <div className="TempGoldmanHomePage">
        <header>
          <Link href="/">
            <CambioLogo className="cambio-logo" />
          </Link>
          <HovercardToggle
            hovercardClassName="account-hovercard"
            contents={
              <SelectableList
                items={[
                  {
                    key: "signout",
                    display: "Sign out",
                    onClick: () => logout().catch((error) => false),
                  },
                ]}
              />
            }
            alignment="right"
          >
            <IconButton icon="user-circle" size="large" />
          </HovercardToggle>
        </header>
        <main>
          <h1>Space Meter Assignment Survey</h1>
          {isLoading ?
            <Spinner />
          : null}
          {hasErrored ?
            <ErrorState />
          : null}
          {hasLoaded ?
            <PropertiesTable properties={propertiesCollection.toJSON()} />
          : null}
        </main>
      </div>
    </CambioPage>
  );
}

export default withNotificationContextProvider(withModalContextProvider(TempGoldmanHomePage));

function PropertiesTable({ properties }: { properties: EnhancedSpace[] }) {
  const { launch } = useModalContext();
  const getRow = (property: EnhancedSpace) => ({
    onClick: () => launch(<SpaceMeterAssignmentModal property={property} />),
    key: property.token,
    row: [
      property.name,
      property.address__shortname,
      property.address__city,
      property.address__state,
      property.address__postal_code,
      property.address__country,
      {
        content:
          property.area ? parseFloat(property.area.toFixed(0)).toLocaleString("en-US") : "--",
        compare: property.area,
      },
      {
        content: property.asset_class?.display_text || "--",
        compare: property.asset_class?.display_text,
      },
      <MeterAssignmentCell property={property} />,
    ],
  });

  return (
    <Table
      head={[
        { content: "Property", sort: true },
        { content: "Address", sort: true },
        { content: "City", sort: true },
        { content: "State or Province", sort: true },
        { content: "Postal Code", sort: true },
        { content: "Country", sort: true },
        { content: "Area (sf)", sort: true, numeric: true },
        { content: "Asset Class", sort: true },
        { content: "Meter Assignment" },
      ]}
      body={properties.map(getRow)}
      copyable={false}
      itemsPerPage={10}
    />
  );
}

const getSMAResource: ExecutorFunction<"spaceMeterAssignments", { propertyToken: string }> = ({
  propertyToken,
}) => ({
  spaceMeterAssignments: { params: { property_token: propertyToken } },
});

function MeterAssignmentCell({ property }: { property: EnhancedSpace }) {
  const { isLoading, hasLoaded, hasErrored, spaceMeterAssignmentsCollection } = useResources(
    getSMAResource,
    { propertyToken: property.token },
  );

  return (
    <span className="MeterAssignmentCell">
      {isLoading ? getLoadingCell() : null}
      {hasErrored ? getEmptyCell() : null}
      {hasLoaded ?
        spaceMeterAssignmentsCollection.length ?
          <>
            <SvgIcon name="check" />
            Meters assigned <span className="middot" /> <Button flavor="link">Edit</Button>
          </>
        : <Button size="small">Assign Meters</Button>
      : null}
    </span>
  );
}
