import { shouldSeeTempGoldmanPage } from "@/layouts/AppLayout";
import DashboardPage from "@/pages/DashboardPage";
import TempGoldmanHomePage from "@/pages/TempGoldmanHomePage";

export default function Dashboard() {
  // TODO: remove when Goldman has full access to the Cambio app
  if (shouldSeeTempGoldmanPage()) {
    return <TempGoldmanHomePage />;
  }

  return <DashboardPage />;
}
